import request from '@/tools/request';
import { Control, Response } from '../types/home';

export const install: Control = () => {
  return request({
    url: '/demo/index/install',
  }) as Promise<Response>;
};

export const uninstall: Control = () => {
  return request({
    url: '/demo/index/uninstall',
  }) as Promise<Response>;
};
