
import { defineComponent } from 'vue';
import { install, uninstall } from '../api/home';

export default defineComponent({
  name: 'Home',
  setup() {
    const installApp = async () => {
      const { status } = await install();
      if (status) alert('安装成功');
    };
    const unInstallApp = async () => {
      const { status } = await uninstall();
      if (status) alert('卸载成功');
    };

    return {
      installApp,
      unInstallApp,
    };
  },
});
